const ServiceData = [
  {
    id: 1,
    img_src: 'https://resource.yuantiaoshi.com/officialWebsite/logo.png',
    title: '源调试',
    upload: '',
    description: '最新版',
  },
  {
    id: 6,
    img_src:
      'https://resource.yuantiaoshi.com/officialWebsite/zi.png',
    title: '字体搬运工',
    description: '直达官网',
    upload: 'https://font.sucai999.com',
  },
  

  {
    id: 5,
    img_src:
      'https://p9-arcosite.byteimg.com/tos-cn-i-goo7wpa0wc/57454a8aeb2041a887a149c0674f83d7~tplv-goo7wpa0wc-image.image',
    title: '抖音直播伴侣',
    description: '直达官网',
    upload: 'https://streamingtool.douyin.com/',
  },
  {
    id: 4,
    img_src:
      'https://resource.yuantiaoshi.com/officialWebsite/OBS_Studio_Logo.png',
    title: 'OBS',
    description: '30.1.2版本',
    upload: 'https://resource.yuantiaoshi.com/OBS%2030.exe',
  },
  {
    id: 2,
    img_src:
      'https://resource.yuantiaoshi.com/officialWebsite/OBS_Studio_Logo.png',
    title: 'OBS',
    description: '28.0.2版本',
    upload: 'https://resource.yuantiaoshi.com/OBS-Studio-28.0.2-x64.exe',
  },
  {
    id: 3,
    img_src:
      'https://resource.yuantiaoshi.com/officialWebsite/OBS_Studio_Logo.png',
    title: 'OBS',
    description: '29.1.2版本',
    upload: 'https://resource.yuantiaoshi.com/OBS%2029.exe',
  },
 
];

export default ServiceData;
