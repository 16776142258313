import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import Navbar from './Pages/Navbar';
import Home from './Pages/Home';
import axios from 'axios';
import AOS from 'aos';
import 'aos/dist/aos.css';
const App = () => {
  useEffect(async () => {
    AOS.init();
    try {
      await axios.post('/api1/index/visitOfficialWeb');
    } catch (error) {
      console.log(error);
    }
  });
  return (
    <>
      <Navbar />

      <Routes>
        <Route
          exact
          path="/"
          element={
            <>
              <Home />
            </>
          }
        />
      </Routes>
    </>
  );
};

export default App;
