import React, { useState } from 'react';
import logo_img from './images/logo.png';
import 'font-awesome/css/font-awesome.min.css';
import './Navbar.css';
import ServiceData from "./ServiceData";
const Navbar = () => {
  const go = (e) => {
    const obj = ServiceData.find(item => item.id == e)
    window.open(obj.upload);
  }
  return (
    <>
      <div className="nav-container">
        <div className="logo">
          <img src={logo_img} alt="om dental clinic logo" />
          <h2>源调试</h2>
        </div>
        <div className='list'>
          <div className="item">
            <div>OBS下载</div>
            <div className="version">
              <div onClick={()=>go(2)}>OBS 28版本</div>
              <div onClick={()=>go(3)}>OBS 29版本</div>
              <div onClick={()=>go(4)}>OBS 30版本</div>
            </div>
          </div>
          <div className="item">
            <div>关于</div>
            <div className="version1">
              <div>客服QQ群:715581670</div>
              <div>在线时间:9:30-19:00</div>
              <div style={{margin:'8px'}}>企业微信:</div>
              <img src="https://resource.yuantiaoshi.com/officialWebsite/%E4%BA%8C%E7%BB%B4%E7%A0%81.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
