import React from 'react';
import './Home.css';
import 'font-awesome/css/font-awesome.min.css';
import Services from '../Pages/Services';
import axios from 'axios';
const Home = () => {
  const go = async () => {
    try {
      const upload = await axios.get('/api');
      console.log(upload,'upload');
      window.open(upload.data['dict_remark']);
    } catch (error) {}
  };
  return (
    <>
      <section id="home">
        <div className="slider_container">
          <div className="slider-images">
            <div className="slider_image">
              <img
                className="w-100"
                src={
                  'https://resource.yuantiaoshi.com/officialWebsite/home1.png'
                }
                alt="First slide"
                data-aos="fade-down"
              />
            </div>

            <div className="front_container">
              <div className="front_page_info">
                <h2
                  style={{
                    fontSize: '60px',
                    margin: '0',
                    textAlign: 'left',
                    lineHeight: '1.2',
                    color: 'hsl(218, 70%, 18%);',
                  }}
                  data-aos="fade-left"
                  data-aos-duration="1000"
                >
                  OBS开播如此简单
                </h2>
                <p
                  style={{ fontSize: '24px' }}
                  data-aos="fade-right"
                  data-aos-duration="1000"
                >
                  告别复杂直播设置 实现高画质直播
                </p>
                <div className="social_links" onClick={go}>
                  下载源调试
                </div>
              </div>
            </div>
          </div>
        </div>
        {<Services />}
        <div className="clinic_container">
          <div className="clinic_info">
            <div className="basic_info" id="info_01">
              <h2>自动推流</h2>
              <p>打开自动操作,只需点击直播伴侣开启直播便可完成推流,方便简单</p>
            </div>
            <div className="basic_info" id="info_02">
              <h2>OBS 一键调试</h2>
              <p>一键保存或调试OBS直播配置</p>
            </div>
            <div className="basic_info" id="info_03">
              <h2>音效软件</h2>
              <p>有默认音乐,也可自定义上传音乐,支持一键播放</p>
            </div>
            <div className="basic_info" id="info_04">
              <h2>免费客服</h2>
              <p>有任何软件问题可找客服解决</p>
            </div>
          </div>
        </div>
        <a href="beian.miit.gov.cn" className="beian">
          鄂ICP备2023009234号
        </a>
      </section>
    </>
  );
};

export default Home;
