import React from 'react';
import ServiceData from './ServiceData';
import './Services.css';
import axios from 'axios';
const Services = () => {
  const go = async (e) => {
    if (e.id != 1) {
      window.open(e.upload);
    } else {
      try {
        const upload = await axios.get('/api');
        window.open(upload.data.msg);
      } catch (error) {}
    }
  };
  return (
    <>
      <section id="our-services">
        <div className="services_container">
          <h2 className="services_header">
            <span>
              <i class="fa-solid fa-angles-right"></i>
            </span>
            软件下载
          </h2>
          <div className="service_info_container">
            {ServiceData.map((e, index) => (
              <div
                className="service_info"
                key={index}
                data-aos="fade-right"
                data-aos-duration="1000"
                onClick={() => go(e)}
              >
                <img
                  className="service_image_details"
                  src={e.img_src}
                  alt="dental images"
                />
                <h2 className="service__title">{e.title}</h2>
                <p className="service_detail_info">{e.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;
